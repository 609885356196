const { REACT_APP_NAME } = process.env;
import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import PageContainer from "src/components/container/PageContainer"
import Paper from '@mui/material/Paper';
import { ChangeEvent, useEffect, useState } from "react";
import { useFinanceHistoryMutation } from "src/redux/api/cabinet";
import dayjs from "dayjs";


export const BalanceHistory = () => {
    const [rows, setRows] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [getData, { isLoading, isSuccess, isError }] = useFinanceHistoryMutation();
    const getRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await getData({ jwt })
            .unwrap()
            .then((payload: any) => {
                setRows(payload.data);
                // setAnswerData(payload.data);
                // dispatch(updProfileData(payload.data));
                // notify('Данные успешно изменены');
            })
            .catch((error: any) => {
                // console.log('err getPartnersData');
                // console.log(error);
                // notifyErr(error.data.message);
                // setAlert(true);
                // setAlertMsg(error.data.message);
                // setAlertStatus('success');
            })
    }

    useEffect(() => {
        getRequest();
    }, [])
    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Переводы`} description="Перевод средств на другой аккаунт">
                <Box sx={{ my: 5 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="h6">№</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Тип</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Дата</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Статус</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Описание</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Сумма</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Баланс ЖО</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Баланс NULL</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Баланс</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {rows && rows.map((row: any, i: number) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                        <TableCell component="th" scope="row">
                                            <Typography>{i + 1}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {row.type_id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.amount))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {dayjs(row.created_at).format('DD.MM.YYYY')}
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {dayjs(row.created_at).format('HH:mm:ss')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px', color: row.status_id.color }}>
                                                {row.status_id.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {row.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                {row.balance && row.balance > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.balance)) : 'Неизвестно'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))} */}

                                {rows && rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row: any, i: number) => {
                                        return (
                                            <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                                <TableCell component="th" scope="row">
                                                    <Typography>{i + 1}</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {row.type_id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {dayjs(row.created_at).format('DD.MM.YYYY')}
                                                    </Typography>
                                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {dayjs(row.created_at).format('HH:mm:ss')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px', color: row.status_id.color }}>
                                                        {row.status_id.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {row.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {/* {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.amount))} */}
                                                        {row.amount && row.amount > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.amount)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                                                    </Typography>
                                                </TableCell>

                                                {/* жо */}
                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {row.balance_zho && row.balance_zho > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.balance_zho)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                                                    </Typography>
                                                </TableCell>
                                                {/* нул */}
                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {row.balance_null && row.balance_null > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.balance_null)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                                                    </Typography>
                                                </TableCell>


                                                <TableCell align="center">
                                                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                        {row.balance && row.balance > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.balance)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }

                                {isLoading &&
                                    <>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell align="center" colSpan={7}>
                                                <Skeleton height={'40px'} variant="rounded" />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                }

                                {rows.length === 0 && isSuccess &&
                                    <TableRow
                                        // onClick={() => updPersonInfoIdLocal(row.id)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                    >
                                        <TableCell align="center" colSpan={7}>
                                            <Typography variant="h6" textAlign='center'>Транзакции не найдены</Typography>
                                        </TableCell>
                                    </TableRow>
                                }

                                {isError && <TableRow
                                    // onClick={() => updPersonInfoIdLocal(row.id)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                >
                                    <TableCell align="center" colSpan={7}>
                                        <Typography variant="h6" textAlign='center'>Ошибка при загрузке транзакций</Typography>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                            {/* <TableFooter>

                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage="Строк на странице"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </PageContainer>
        </>
    )
}
