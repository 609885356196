import { Box, Button, IconButton, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { ChangeEvent, FC, useState } from 'react'
import Paper from '@mui/material/Paper';
import dayjs from "dayjs";
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';

interface ITableAnaliz {
    rows: any[]
}

export const TableAnaliz: FC<ITableAnaliz> = ({ rows }) => {


    // const [rows, setRows] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const returnDot = (count: number) => {
        const arr = [];

        for (let i = 0; i < count; i++) {
            arr.push(<FiberManualRecordIcon key={i} sx={{ mr: 1, color: "#151ee9", fontSize: "26px" }} />);
        }

        return arr
    }

    const returnCircle = (count: number, closed: number) => {
        const arr = [];

        for (let i = 0; i < (count - closed); i++) {
            arr.push(<PanoramaFishEyeRoundedIcon key={i} sx={{ mr: 1, color: "#151ee9", fontSize: "24px" }} />);
        }

        return arr
    }
    return (
        <>
            <Box mt={5}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">ID</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Логин</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Дата активации</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6">Заполнение матрицы</Typography>
                                </TableCell>

                                <TableCell align="center">
                                    <Typography variant="h6">Просмотр</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {rows.length > 0 && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, i: number) => {
                                    return (
                                        <TableRow hover key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                            <TableCell component="th" scope="row">
                                                <Typography>{i + 1}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.login} <Typography component="span">{row.clones > 0 ? `(Клон ${row.clones})` : "(Основной аккаунт)"}</Typography>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {row.date_activate ? row.date_activate : "Неизвестно"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    {/* {row.closed_text} */}
                                                    {returnDot(row.closed)}
                                                    {returnCircle(row.count_position, row.closed)}
                                                </Typography>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Button
                                                    component='a'
                                                    href={row.link}
                                                    target='_blank'
                                                    rel='noreferer'
                                                    sx={{ fontWeight: 500, fontSize: '16px' }}>
                                                    Просмотреть

                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }

                            {rows.length === 0 && <>
                                <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
                                    <TableCell scope="row" colSpan={5}>
                                        <Typography textAlign='center' variant='h6'>В структуре нет людей</Typography>
                                    </TableCell>
                                </TableRow>
                            </>}

                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Строк на странице"
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </>
    )
}
