import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { usePayMarketingMutation } from 'src/redux/api/cabinet';
// import { useNavigate } from 'react-router';
import { Alerting } from 'src/views/components_cabinet/Alerting';
import { BronButton } from './BronButton';

interface Idata {
    data: any
    params: any
}

export const EmptyCardPayClone: FC<Idata> = ({ data, params }) => {
    const [stateModal, setStateModal] = useState<boolean>(false);
    const [JWClone, setJWClone] = useState<boolean>(false);
    const [techClone, settechClone] = useState<number>(0);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const getModalPayClone = (type: boolean) => {
        setJWClone(type);
        setStateModal(p => !p)
    }


    const [reqData, { isLoading, isError }] = usePayMarketingMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                setStateModal(p => !p)
                window.location.reload()

            })
            .catch((error: any) => {

                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    const payClone = (body: any) => {
        Request(body)
    }


    return (
        <>

            <Box sx={{ width: '100%', height: '100%', opacity: data.visible ? 1 : 0 }} display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                <Box display='flex' justifyContent='center' mb={1}>
                    <Chip sx={{ background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "16px" }} label='0' />
                </Box>
                <Card sx={{
                    width: '100%', background: '#E1BBF8', border: '1px solid #1700CA',
                    minHeight: '150px',
                    height: "100%",
                    paddingBottom: 0
                }} >

                    {data.visible && <CardContent sx={{ background: '#E1BBF8', p: 0, pb: 0 }}>
                        <Typography textAlign="center" variant="h6" component='h3' sx={{ mb: 1 }}>
                            Место пустое
                        </Typography>
                        <Box display='flex' mt={1}>
                            <Avatar
                                sx={{ width: { xs: "2rem", lg: '2.8rem' }, height: { xs: "2rem", lg: '2.8rem' } }}
                                src={levelPlaceholder}
                            />

                            <Box mt={0} display='flex' justifyContent='center' flexDirection='column' alignItems='start' ml={1}>
                                {data.pay &&
                                    <>
                                        <Button sx={{ mb: 1 }} size='small' onClick={() => {
                                            getModalPayClone(false)
                                            settechClone(0);
                                        }}>
                                            Купить клона
                                        </Button>
                                        <Button size='small' sx={{ background: "#1700ca", color: "#fff", mb: 1 }} onClick={() => {
                                            getModalPayClone(false);
                                            settechClone(1);
                                        }}>
                                            Купить тех. клона
                                        </Button>
                                    </>
                                }

                                {data.pay_jewelry && <Button sx={{ mb: 1 }} size='small' onClick={() => {
                                    getModalPayClone(true)
                                    settechClone(0);
                                }
                                }>

                                    Купить юв. клона
                                </Button>}

                                {data.bron &&
                                    <BronButton bronData={data} />
                                }

                            </Box>

                        </Box>

                        {/* <Box display='flex' justifyContent='center' mb={1} >
                            <Chip sx={{ background: "transparent", borderRadius: '6px' }}
                                label={""}
                            />
                        </Box> */}
                    </CardContent>}

                </Card>
            </Box>


            {data.visible && <Dialog
                open={stateModal}
                onClose={() => setStateModal(p => !p)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <>
                    {getAlert && <Alerting get={getAlert} />}
                    <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                        <IconButton sx={{ ml: 'auto' }} onClick={() => setStateModal(p => !p)} autoFocus>
                            <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={3}>
                            <Typography variant="h3" mb={5} textAlign='center' sx={{ fontWeight: 500 }}>
                                Вы уверены что хотите поставить {!JWClone ? techClone === 1 ? "Тех. клона" : "Клона" : "Ювелирного Клона"}?
                            </Typography>
                        </Box>
                        <Button color='error' size='large' fullWidth sx={{ fontSize: "20px" }} onClick={() => {
                            const body: any = { ...params };
                            body.mentor_id = Number(data.mentor_id);

                            if (JWClone) body.jeverly = true;
                            else body.no_jeverly = true

                            body.teh = techClone;

                            // console.log(body);
                            payClone(body);
                        }}>
                            Да, я уверен!
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant='outlined' onClick={() => setStateModal(p => !p)}>
                            Отменить
                        </Button>
                    </DialogActions>
                </>

            </Dialog>}

        </>
    )
}
