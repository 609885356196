import { Box, Button, Checkbox, Drawer, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react"
import { UserCard } from "./UserCard";
import { EmptyCard } from "./EmptyCard";
import { UserInfoModal } from "./UserInfoModal";
import { Link, useSearchParams } from "react-router-dom";
import { EmptyCardPayClone } from "./EmptyCardPayClone";
import { useSelector } from "react-redux";
import { AppState } from "src/store/Store";
import { useSetAbonentMutation, usePayAbonentMutation, usePayMarketingMutation } from "src/redux/api/cabinet";
import { Alerting } from "src/views/components_cabinet/Alerting";
import { StructureChain } from "./StructureChain";
import { SearchInStructure } from "./SearchInStructure";
import TuneIcon from '@mui/icons-material/Tune';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TextTimer } from "./TextTimer";
import { ReturnM } from "./ReturnM";
// import { ReturnM } from "./ReturnM";

interface IStructure {
    structure: any
    program: any
    marketing_type: any
    list_clones: any
    subscribe: any
    listHistory: any[]
    globalClone: boolean
    paymantUserStatus: boolean
    fullData: any
}

export const Structure: FC<IStructure> = ({ fullData, paymantUserStatus, globalClone, structure, program, marketing_type, list_clones, subscribe, listHistory }) => {
    const { data } = useSelector((state: AppState) => state.app);
    const [searchParams] = useSearchParams();
    const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [dataUser, setDataUser] = useState<any>(undefined);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [sebscribeChecked, SetsebscribeChecked] = useState<boolean>(false);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');
    const mentorParam = searchParams.get('m');

    // fullData.levels


    const updateModalState = () => {
        setOpenModal(p => !p)
    }

    const updateDataModal = (data: any) => {
        setDataUser(data)
    }

    const [age, setAge] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    const subscribeSend = () => {
        levelsRequest()
    };

    const [levelsData, { isLoading, isError }] = useSetAbonentMutation();
    const levelsRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await levelsData({ jwt })
            .unwrap()
            .then((payload: any) => {
                SetsebscribeChecked(false);
                setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const [payAboPlataData, { isLoading: payAbonLoad, }] = usePayAbonentMutation();
    const payAboPlataRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await payAboPlataData({ jwt })
            .unwrap()
            .then((payload: any) => {
                // SetsebscribeChecked(true);

                setgetAlert({ type: 1, msg: payload.message });
                window.location.reload();
            })
            .catch((error: any) => {
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const payAbonPlata = () => payAboPlataRequest();

    const [drawState, setDrawState] = useState(false);

    const payCloneForPloshadka = (request: any) => {
        // console.log(request);
        Request(request);
    }

    const [reqData, { isLoading: isLd, isSuccess: isSc }] = usePayMarketingMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // setStateModal(p => !p)
                // window.location.reload()
                setgetAlert({ type: 1, msg: payload.message });

            })
            .catch((error: any) => {

                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    useEffect(() => {
        SetsebscribeChecked(subscribe.subscribe_paid);
    }, []);

    return (
        <>
            {getAlert && !drawState && <Box sx={{ zIndex: 7777 }}>
                <Alerting get={getAlert} />
            </Box>}

            {/* маркетинг */}
            <>
                <Box position='relative' sx={{ display: { xs: "none", md: "block" }, position: "fixed", top: "29vh", right: "1rem", zIndex: 5000 }} display='inline-flex' flexDirection='column' alignItems='end'>
                    <Box mb={1} sx={{}}>
                        <Typography textAlign='center' variant="h5" sx={{ color: "#0721e9", textDecoration: "underline", fontWeight: 600 }}>Тариф: {program ? program.name : "Неизвестно"}</Typography>
                        <Typography textAlign='center' variant="h5" sx={{ color: "#0721e9", fontWeight: 500 }}>УР: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(levelParam))}</Typography>
                    </Box>

                    <Fab color="primary" variant="extended" onClick={() => setDrawState(true)} sx={{ background: "#ea0606cc" }} aria-label="add">
                        <TuneIcon sx={{ mr: 1, display: { xs: 'none', md: "block" } }} />
                        <Typography sx={{ fontSize: { xs: '16px', fontWeight: 500 } }}>Меню</Typography>
                    </Fab>
                </Box>
                {/* нижнее меню */}
                <Box position='relative' sx={{ display: { xs: "block", md: "none" }, px: 1, position: "fixed", bottom: "2px", right: 0, left: 0, zIndex: 5000 }}>
                    <Box sx={{ background: "#041ee938", py: 0.8 }}>
                        <Grid container sx={{ px: 2 }}>
                            <Grid item xs={8}>
                                <Box>
                                    <Typography variant="h5" sx={{ color: "#0721e9", textDecoration: "underline", fontWeight: 600 }}>Тариф: {program ? program.name : "Неизвестно"} </Typography>
                                    <Typography variant="h5" sx={{ color: "#0721e9", fontWeight: 500 }}>УР: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(levelParam))}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} display='flex' justifyContent='end'>
                                <Fab color="primary" variant="extended" onClick={() => setDrawState(true)} sx={{ background: "#ea0606cc" }} aria-label="add">
                                    <TuneIcon sx={{ mr: 1, display: "block" }} />
                                    <Typography sx={{ fontSize: { xs: '16px', fontWeight: 500 } }}>Меню</Typography>
                                </Fab>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* ВСПЛЫВАШКА СПРАВА в маркетинге */}
                <Drawer
                    anchor='right'
                    open={drawState}
                    onClose={() => setDrawState(false)}
                    sx={{ position: "relative" }}
                >
                    <IconButton sx={{ position: 'absolute', top: "0.5rem", right: "1rem", background: '#0510ff7d', "&:hover": { background: "#0510ffb8" } }} onClick={() => setDrawState(false)}>
                        <CloseRoundedIcon sx={{ color: '#fff' }} />
                    </IconButton>
                    <Box sx={{ py: 1, px: 3, mt: 10 }}>

                        {getAlert && drawState && <Box sx={{ zIndex: 7777 }}>
                            <Alerting get={getAlert} />
                        </Box>}
                        {/* новая версия */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} display='inline-flex' alignItems='center' justifyContent='center' flexDirection='column'>
                                <Box display='inline-block' mb={3}>
                                    <Typography textAlign='center' height='100%' width='100%' sx={{ fontWeight: 500, fontSize: '24px', mb: 2, color: "#0119f9", borderBottom: "3px solid #0119f9" }}>
                                        Тариф {program ? program.name : "Неизвестно"} - <Typography component="span" sx={{ color: "red", fontSize: "inherit", fontWeight: 500 }}> {<ReturnM levels={fullData.levels} />}</Typography>
                                    </Typography>
                                </Box>
                                {programParam !== '6' && programParam !== '7' && <Typography mb={3} textAlign='center' height='100%' width='100%' sx={{ fontWeight: 500, fontSize: '20px', pb: 1, color: "#0119f9" }}>Баланс портфеля: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(data.balance))}</Typography>}

                                {/* {programParam === '6' && <Typography mb={3} textAlign='center' height='100%' width='100%' sx={{ fontWeight: 500, fontSize: '20px', pb: 1, color: "#0119f9" }}>Баланс ЖО: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(data.balance_zho))}</Typography>} */}
                                {fullData.bron_position > 0 &&
                                    <Button variant="contained" component={Link} fullWidth sx={{ minHeight: "54px", fontSize: "18px", textAlign: "center", mb: 3 }}
                                        to={`?p=${programParam}&l=${levelParam}&m=${fullData.bron_position}`}>Моя бронь</Button>
                                }

                                {/* программа take */}
                                {programParam === '8' &&
                                    <>
                                        <Box mb={3} sx={{ borderRadius: "6px", border: "3px solid  #0119f9", p: 1, display: 'inline' }}>
                                            <Typography textAlign='center' sx={{ fontWeight: 500, fontSize: '18px', color: "red", mb: 1 }}>Банк ПКлонов "TAKE"</Typography>
                                            <Box display='flex' flexDirection='column' alignItems='center'>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М1: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_1))}</Box>
                                                </Typography>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М2: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_2))}</Box>
                                                </Typography>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М3: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_3))}</Box>
                                                </Typography>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М4: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_4))}</Box>
                                                </Typography>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М5: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_5))}</Box>
                                                </Typography>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    ПКлоны #М9: <Box component='span' sx={{ fontSize: "20px" }}>{new Intl.NumberFormat("ru", { minimumFractionDigits: 0 }).format(Number(data.take_clone_9))}</Box>
                                                </Typography>
                                            </Box>
                                            <Typography textAlign='center' sx={{ fontWeight: 400, fontSize: '16px', color: "red", mb: 1 }}>*ПК (Персональные Клоны)</Typography>
                                        </Box>
                                    </>
                                }

                                {/* блок информации с таймером */}
                                {fullData.abonent_time > 0 &&
                                    <Box mb={3} sx={{ borderRadius: "6px", border: "3px solid  #0119f9", width: "100%", p: 1 }}>

                                        {/* равно null */}
                                        {programParam === '7' && <>
                                            <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                Баланс платформы: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(data.balance_null))}
                                            </Typography>

                                            <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                Ваша задолженность: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(fullData.abonent_dolg > 0 ? fullData.abonent_dolg : 0))}
                                            </Typography>
                                        </>}

                                        {/* равно space */}
                                        {programParam == '6' &&
                                            <>
                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    Общий банк клонов: {fullData.bank_clones}
                                                </Typography>

                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    Баланс платформы: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(data.balance_zho))}
                                                </Typography>

                                                <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                                    Ваша задолженность: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(fullData.abonent_dolg > 0 ? fullData.abonent_dolg : 0))}
                                                </Typography>
                                            </>
                                        }

                                        <Typography mb={1} sx={{ fontWeight: 500, fontSize: '18px', color: "#0119f9" }}>
                                            Автосписание в платформе тарифа "{program.name}"
                                        </Typography>

                                        {/* таймер */}
                                        <Box>
                                            <TextTimer expiryTimestamp={new Date(fullData.abonent_time * 1000)} />
                                        </Box>

                                    </Box>}

                                {/* блок подписки на абонентку */}
                                {programParam === '7' &&
                                    <>
                                        <FormGroup sx={{ width: "100%", border: `2px solid ${!sebscribeChecked ? '#777777' : '#061dea'} `, p: 1, borderRadius: "8px", mb: 1 }}>
                                            {sebscribeChecked && <FormControlLabel sx={{ fontSize: '40px' }} control={<Checkbox disabled={isLoading ? true : false} onChange={() => {
                                                if (sebscribeChecked) subscribeSend();
                                                else alert("Уже выбрано")
                                            }} />} label={<Typography sx={{ color: "#061dea" }} variant="h4">
                                                Отказ от абонентской платы
                                            </Typography>} />}

                                            {!sebscribeChecked && <FormControlLabel control={<Checkbox checked={true} disabled />} label={<Typography sx={{ color: "#777777" }} variant="h4">
                                                Отказ от абонентской платы
                                            </Typography>} />}
                                        </FormGroup>
                                        <Box mb={3}>
                                            <Typography variant="h6" mb={1} color={subscribe.subscribe_error ? "error" : "primary"}>
                                                {subscribe.subscribe_comment}
                                            </Typography>

                                            {/* <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                disabled={LS ? false : true}
                                                {...register("politics", { required: { value: true, message: "Подтвердите согласие с условиями политики конфиденциальности" } })}
                                            />} label={<Typography>Согласен с условиями <Link target='_blank' to="/politics">политики конфиденциальности</Link></Typography>} />
                                        </FormGroup> */}

                                            {/* {subscribe.subscribe_error &&
                                                <Box mt={1}>
                                                    <Button
                                                        disabled={payAbonLoad}
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={payAbonPlata}
                                                    >
                                                        Оплатить абон.плату
                                                    </Button>
                                                </Box>
                                            } */}
                                        </Box>
                                    </>
                                }

                                {globalClone &&
                                    <Box mb={3} width='100%'>
                                        <Typography sx={{ mb: 1, fontSize: "16px", fontWeight: 600 }}>Приобритение дополнительного клона</Typography>

                                        <Button disabled={isLd} sx={{ minHeight: "54px", fontSize: "18px", width: "100%" }} onClick={() => payCloneForPloshadka({
                                            program: programParam,
                                            level: levelParam
                                        })}>
                                            Купить клона ЖО
                                        </Button>
                                    </Box>
                                }

                                <Button variant="contained" component={Link} fullWidth sx={{ minHeight: "54px", fontSize: "18px", textAlign: "center", mb: 3 }} to={`?p=${structure.position_1.asc_clone.program}&l=${structure.position_1.asc_clone.level}&m=${structure.position_1.asc_clone.mentor_id}`}>Куда встанет новичок</Button>

                                <Box
                                    mb={3}
                                    sx={{ width: "100%" }}
                                    display='flex'
                                    justifyContent='center'
                                >
                                    <FormControl
                                        sx={{ width: "100%" }}
                                    >
                                        <FormLabel sx={{ fontSize: "16px", fontWeight: 600 }}>
                                            Мои места
                                        </FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                "& > div, svg": {
                                                    zIndex: 7778
                                                }
                                            }}
                                        >
                                            {list_clones && list_clones.map((e: any, i: number) => {
                                                return <MenuItem key={i} value={e.name} sx={{ p: 0 }}>
                                                    <Typography sx={{ p: 1.6, fontWeight: 500, width: "100%" }} component={Link} to={`?p=${e.program}&l=${e.level}&m=${e.mentor_id}`}>
                                                        {e.name}
                                                    </Typography>
                                                </MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>

                                {/* поиск пользователей */}
                                <SearchInStructure />
                            </Grid>


                        </Grid>

                        {/* старая версия */}
                        <Grid sx={{ display: 'none' }} container spacing={1} display={'flex'} alignItems='end'>

                            <Grid item xs={12}>
                                <Typography mb={3} textAlign='center' height='100%' width='100%' sx={{ color: "red", fontWeight: 500, fontSize: '19px', p: 2, border: "2px solid red", borderRadius: "4px" }}>Банк клонов ЖО: {fullData.bank_clones ? fullData.bank_clones : '0 Р'}</Typography>

                                <Typography mb={3} textAlign='center' height='100%' width='100%' sx={{ color: "red", fontWeight: 500, fontSize: '19px', p: 2, border: "2px solid red", borderRadius: "4px" }}>Баланс клонов: {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(data.clone_balance))}</Typography>

                                <Button variant="contained" component={Link} fullWidth sx={{ minHeight: "54px", fontSize: "18px", textAlign: "center", mb: 3 }} to={`?p=${structure.position_1.asc_clone.program}&l=${structure.position_1.asc_clone.level}&m=${structure.position_1.asc_clone.mentor_id}`}>Куда встанет новичок</Button>

                                {/* блок подписки на абонентку */}
                                {subscribe && subscribe.subscribe && programParam !== '6' &&
                                    <>
                                        <FormGroup sx={{ border: `2px solid ${!sebscribeChecked ? '#777777' : '#061dea'} `, p: 1, borderRadius: "8px", mb: 1 }}>
                                            {sebscribeChecked && <FormControlLabel sx={{ fontSize: '30px' }} control={<Checkbox disabled={isLoading ? true : false} onChange={() => {
                                                if (sebscribeChecked) subscribeSend();
                                                else alert("Уже выбрано")
                                            }} />} label={<Typography sx={{ color: "#061dea" }} variant="h5">
                                                Отказ от абонентской платы
                                            </Typography>} />}

                                            {!sebscribeChecked && <FormControlLabel control={<Checkbox checked={true} disabled />} label={<Typography sx={{ color: "#777777" }} variant="h5">
                                                Отказ от абонентской платы
                                            </Typography>} />}
                                        </FormGroup>
                                        <Box mb={2}>
                                            <Typography variant="h6" mb={1} color={subscribe.subscribe_error ? "error" : "primary"}>
                                                {subscribe.subscribe_comment}
                                            </Typography>
                                        </Box>
                                    </>
                                }

                                <Box
                                    mb={3}
                                    sx={{ width: "100%" }}
                                    display='flex'
                                    justifyContent='center'
                                >
                                    <FormControl
                                        sx={{ width: "100%" }}
                                    >
                                        <FormLabel sx={{ fontSize: "16px", fontWeight: 600 }}>
                                            Мои места
                                        </FormLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={handleChange}
                                            sx={{
                                                "& > div, svg": {
                                                    zIndex: 7778
                                                }
                                            }}
                                        >
                                            {list_clones && list_clones.map((e: any, i: number) => {
                                                return <MenuItem key={i} value={e.name} sx={{ p: 0 }}>
                                                    <Typography sx={{ p: 1.6, fontWeight: 500, width: "100%" }} component={Link} to={`?p=${e.program}&l=${e.level}&m=${e.mentor_id}`}>
                                                        {e.name}
                                                    </Typography>
                                                </MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box mb={3}>
                                    <SearchInStructure />
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
                </Drawer>

                <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' pt={1} pb={2}>
                    {/* цепь структуры */}
                    <StructureChain arrChain={listHistory} />
                </Box>

                {/* первая линия */}
                <Grid container spacing={2} display='flex' justifyContent='center' sx={{ position: "relative" }}>
                    <Grid item xs={12} display='flex' justifyContent='center' position='relative'>
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_1
                                ? <EmptyCard />
                                : structure.position_1.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_1} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_1.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_1} place='1' />}
                        </Box>
                    </Grid>
                </Grid >

                {/* тест вторая линия */}
                {/* <Box display='flex' justifyContent='space-between' mb={1}>
                    <Box display='flex' justifyContent='center' sx={{ background: "red", flex: 1, p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_2
                                ? <EmptyCard />
                                : structure.position_2.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_2} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_2.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_2} place='2' />}
                        </Box>
                    </Box>

                    <Box display='flex' justifyContent='center' sx={{ background: "green", flex: 1, p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_3
                                ? <EmptyCard />
                                : structure.position_3.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_3} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_3.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_3} place='3' />}
                        </Box>
                    </Box>
                </Box> */}
                {/* вторая линия */}
                <Grid container spacing={1} display='flex' justifyContent='center'>
                    <Grid item xs={marketing_type === 2 ? 6 : 4} display='flex' height='100%' justifyContent='center' >
                        <Box sx={{ width: "260px", height: "100%" }}>
                            {!structure.position_2
                                ? <EmptyCard />
                                : structure.position_2.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_2} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_2.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_2} place='2' />}
                        </Box>

                    </Grid>
                    <Grid item xs={marketing_type === 2 ? 6 : 4} display='flex' height='100%' justifyContent='center'>
                        <Box sx={{ width: "260px", height: "100%" }}>
                            {!structure.position_3
                                ? <EmptyCard />
                                : structure.position_3.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_3} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_3.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_3} place='3' />}
                        </Box>
                    </Grid>
                    {
                        marketing_type === 3 &&
                        <Grid item xs={4} display='flex' justifyContent='center'>
                            <Box sx={{ width: "260px" }}>
                                {!structure.position_4
                                    ? <EmptyCard />
                                    : structure.position_4.position_empty === true
                                        ? <EmptyCardPayClone data={structure.position_4} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_4.position }} />
                                        : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_4} place='4' />}
                            </Box>
                        </Grid>
                    }
                </Grid >

                {/* тест третья линия */}
                {/* <Box display='flex' justifyContent='space-between' mb={1}>
                    <Box display='flex' justifyContent='center' sx={{ background: "red", flex: "1 0 21%", p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_4
                                ? <EmptyCard />
                                : structure.position_4.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_4} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_4.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_4} place='4' />}
                        </Box>
                    </Box>

                    <Box display='flex' justifyContent='center' sx={{ background: "green", flex: "1 0 21%", p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_5
                                ? <EmptyCard />
                                : structure.position_5.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_5} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_5.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_5} place='5' />}
                        </Box>
                    </Box>

                    <Box display='flex' justifyContent='center' sx={{ background: "red", flex: "1 0 21%", p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_6
                                ? <EmptyCard />
                                : structure.position_6.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_6} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_6.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_6} place='6' />}
                        </Box>
                    </Box>

                    <Box display='flex' justifyContent='center' sx={{ background: "green", flex: "1 0 21%", p: .5 }} >
                        <Box sx={{ width: "260px" }}>
                            {!structure.position_7
                                ? <EmptyCard />
                                : structure.position_7.position_empty === true
                                    ? <EmptyCardPayClone data={structure.position_7} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_7.position }} />
                                    : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_7} place='7' />}
                        </Box>
                    </Box>
                </Box> */}


                {/* третья линия */}

                {marketing_type !== 3 && <>
                    {md && Number(mentorParam) > 0 &&
                        <Grid container spacing={2} display='flex' justifyContent='center'>
                            <Grid item xs={3} display='flex' height='100%' justifyContent='center'>
                                <Box sx={{ width: "260px" }}>
                                    {!structure.position_4
                                        ? <EmptyCard />
                                        : structure.position_4.position_empty === true
                                            ? <EmptyCardPayClone data={structure.position_4} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_4.position }} />
                                            : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_4} place='4' />}
                                </Box>
                            </Grid>
                            <Grid item xs={3} display='flex' height='100%' justifyContent='center'>
                                <Box sx={{ width: "260px" }}>
                                    {!structure.position_5
                                        ? <EmptyCard />
                                        : structure.position_5.position_empty === true
                                            ? <EmptyCardPayClone data={structure.position_5} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_5.position }} />
                                            : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_5} place='5' />}
                                </Box>
                            </Grid>
                            <Grid item xs={3} display='flex' height='100%' justifyContent='center'>
                                <Box sx={{ width: "260px" }}>
                                    {!structure.position_6
                                        ? <EmptyCard />
                                        : structure.position_6.position_empty === true
                                            ? <EmptyCardPayClone data={structure.position_6} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_6.position }} />
                                            : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_6} place='6' />}
                                </Box>
                            </Grid>
                            <Grid item xs={3} display='flex' height='100%' justifyContent='center'>
                                <Box sx={{ width: "260px" }}>
                                    {!structure.position_7
                                        ? <EmptyCard />
                                        : structure.position_7.position_empty === true
                                            ? <EmptyCardPayClone data={structure.position_7} params={{ program: Number(programParam), level: Number(levelParam), position: structure.position_7.position }} />
                                            : <UserCard updateDataModal={updateDataModal} updateModalState={updateModalState} data={structure.position_7} place='7' />}
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </>}


                {dataUser && <UserInfoModal data={dataUser} stateModal={openModal} updateModalState={updateModalState} />}
            </>

        </>
    )
}
