const { REACT_APP_BACK } = process.env;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cabinet = createApi({
  reducerPath: 'cabinet',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_BACK,
  }),
  endpoints: (builder) => ({
    // getauthHome: builder.query<any[], string>({
    //   query: (route) => `api/${route}`,
    // }),
    // getauthMarks: builder.query<any[], string>({
    //   query: (route) => `api/${route}`,
    // }),

    // getStructure: builder.mutation({
    //   query: ({ body, jwt }) => ({
    //     url: `api/structure`,
    //     method: 'POST',
    //     body: body,
    //     headers: {
    //       Accept: 'application/json',
    //       Authorization: `Bearer ${jwt}`,
    //     },
    //   }),
    // }),
    updUserData: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/update-info`,
        method: 'POST',
        body,
        formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getTeams: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/teams`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    financeAdd: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/finance-add`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    financeOut: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/finance-out`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    financeTransfer: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/finance-transfer`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    financeTransferBetweenMyBalances: builder.mutation({
      query: ({ body, jwt }) => ({
        url: `api/balance-system`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    financeHistory: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/finance-operations`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    viewPosition: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/views-position`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    userInfo: builder.mutation({
      query: ({ jwt, user_id }) => ({
        url: `api/user-info`,
        method: 'POST',
        body: { user_id: user_id },
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getLevels: builder.mutation({
      query: ({ jwt, program }) => ({
        url: `api/get-level`,
        method: 'POST',
        body: { program: program },
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getPromo: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/get-promo`,
        method: 'POST',
        // body: { program: program },
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    payMarketing: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/pay-marketing`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),

    payApiPayeer: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/payeer`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    homeStatistics: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/home-statistics`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    telegramAuth: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/telegram-auth`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getTelegram: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/get-telegram`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    telegramOut: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/telegram-out`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getStructure: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/get-structure`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    setAbonent: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/set-abonent`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    payAbonent: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/pay-abonent`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getNotification: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/get-notification`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    searchMarketing: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/search-marketing`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    setAdnCancelBron: builder.mutation({
      query: ({ jwt, body }) => ({
        url: `api/bron`,
        method: 'POST',
        body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
    getsssStructure: builder.mutation({
      query: ({ jwt }) => ({
        url: `api/gets-structure`,
        method: 'POST',
        // body,
        // formData: true,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }),
    }),
  }),
});

export const {
  useGetsssStructureMutation,
  useSetAdnCancelBronMutation,
  useSearchMarketingMutation,
  useGetNotificationMutation,
  usePayAbonentMutation,
  useSetAbonentMutation,
  useGetStructureMutation,
  useTelegramOutMutation,
  useGetTelegramMutation,
  useTelegramAuthMutation,
  useHomeStatisticsMutation,
  usePayApiPayeerMutation,
  usePayMarketingMutation,
  useGetPromoMutation,
  useUpdUserDataMutation,
  useGetTeamsMutation,
  useFinanceAddMutation,
  useFinanceTransferMutation,
  useFinanceOutMutation,
  useFinanceHistoryMutation,
  useViewPositionMutation,
  useUserInfoMutation,
  useGetLevelsMutation,
  useFinanceTransferBetweenMyBalancesMutation,
} = cabinet;
