import { Button } from '@mui/material';
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router';
import { useSetAdnCancelBronMutation } from 'src/redux/api/cabinet';
import { Alerting } from 'src/views/components_cabinet/Alerting';

interface IBronButton {
    bronData: any
}

export const BronButton: FC<IBronButton> = ({ bronData }) => {
    const nav = useNavigate();
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const [reqData, { isLoading, isError }] = useSetAdnCancelBronMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                // setStateModal(p => !p)
                // window.location.reload()
                nav(0);

            })
            .catch((error: any) => {

                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    const setterBron = (body: any) => {
        Request(body)
    }

    return (
        <>
            {getAlert && <Alerting get={getAlert} />}
            {bronData.bron_mentor_id > 0 ?
                <Button size='small' sx={{ mb: 1, border: "1px solid red" }} onClick={() => {
                    const data = {
                        program: bronData.bron_program,
                        level: bronData.bron_level,
                        mentor_id: bronData.bron_mentor_id,
                        position: bronData.bron_position
                    };

                    setterBron(data);
                }}>
                    Забронировать
                </Button>
                :

                <Button size='small' sx={{ background: "red", color: "#fff", mb: 0.5, border: "1px solid red" }} onClick={() => {
                    const data = {
                        program: bronData.bron_program,
                        level: bronData.bron_level,
                        mentor_id: bronData.bron_mentor_id,
                        position: bronData.bron_position
                    };
                    setterBron(data);
                }}>
                    Снять бронь
                </Button>
            }

        </>
    )
}
